'use client';

import {ReactNode} from 'react';

import {useMediaQuery} from '@mui/material';
import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {usePathname} from 'next/navigation';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import toast from 'react-hot-toast';
import {SWRConfig, SWRConfiguration} from 'swr';

import SmallScreen from '@components/small-screen';
import CssVariables from '@src/components/CssVariables';
import Alert from '@src/components/legacy/Alert';
import theme from '@src/library/theme';
import {IntercomProvider} from 'react-use-intercom';

const isServer = typeof window === 'undefined';

const swrConfig: SWRConfiguration = {
  onError(error) {
    const isForbidden =
      error.response?.errors?.[0]?.extensions?.code === 'FORBIDDEN';

    if (isForbidden) {
      const toastInstance = toast.custom((notification) => {
        return (
          <Alert
            classnames={
              notification.visible
                ? 'toastNotificationOpen'
                : 'toastNotificationClosed'
            }
            severity="error"
            onClose={() => {
              toast.dismiss(toastInstance);
            }}
          >
            You don&apos;t have permission to perform this action.
          </Alert>
        );
      });
    }
  },
};

if (!isServer) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    disable_session_recording: process.env.NODE_ENV === 'development',
    session_recording: {
      maskTextSelector: '.ProseMirror',
    },
    capture_pageview: false,
    autocapture: false,
    opt_in_site_apps: true,
  });
}

export default function Providers({children}: {children: ReactNode}) {
  const pathname = usePathname();
  const smallDevice = useMediaQuery('only screen and (max-width: 500px)');
  const isLiveNotes = pathname.includes('live-notes');

  if (smallDevice && !isLiveNotes) {
    return <SmallScreen />;
  }

  return (
    <AppRouterCacheProvider options={{key: 'css', prepend: true}}>
      <PostHogProvider client={posthog}>
        <IntercomProvider appId={process.env.INTERCOM_APP_ID}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <CssVariables />
              <SWRConfig value={swrConfig}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {children}
                </LocalizationProvider>
              </SWRConfig>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntercomProvider>
      </PostHogProvider>
    </AppRouterCacheProvider>
  );
}
