import Box from '@mui/material/Box';

import Typography from '@src/components/legacy/Typography';

const SmallScreen = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '50%',
        textAlign: 'center',
        marginX: 3,
      }}
    >
      <Typography variant="h1">
        This app is not optimized for devices with small screen. Please, log in on a
        computer or tablet.
      </Typography>
    </Box>
  );
};

export default SmallScreen;
