import {ReactNode} from 'react';

import {
  AlertTitle,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
} from '@mui/material';

import clsx from 'clsx';

import css from './Alert.module.css';
import Typography from './Typography';

export type AlertProps = MuiAlertProps & {
  classnames?: Array<string> | string;
  children: ReactNode | string;
  alertTitle?: string;
};

export default function Alert({
  alertTitle,
  children,
  classnames,
  severity,
  ...props
}: AlertProps) {
  return (
    <MuiAlert
      {...props}
      className={clsx([css['alert'], classnames])}
      severity={severity}
    >
      {alertTitle && (
        <AlertTitle>
          <Typography noWrap variant="subtitle2">
            {alertTitle}
          </Typography>
        </AlertTitle>
      )}
      {children}
    </MuiAlert>
  );
}
