import {GlobalStyles} from '@mui/material';

export default function CssVariables() {
  return (
    <GlobalStyles
      styles={{
        html: {
          '--colors-borders-brightgray': '#e6ebf3',
          '--colors-borders-cornflowerblue': '#5c84ee',
          '--colors-borders-gainsboro': '#d6deea',
          '--colors-borders-linkwater': '#ccced0',
          '--colors-borders-royalblue': '#3e5fe4',
          '--colors-borders-solitudegray': '#e1e4e8',
          '--colors-borders-shadowblue': '#7a89aa',
          '--colors-events-warning-action': '#f44336',
          '--colors-events-warning-background': '#feedeb',
          '--colors-events-neutral-background': '#f5f8fb',
          '--colors-events-primary-main': '#3e5fe4',
          '--colors-events-secondary-main': '#1c2131',
          '--colors-events-orange-action': '#ed6c02',
          '--colors-events-orange-background': '#F7EDDF',
          '--colors-typography-light': '#f9fbfd',
          '--colors-typography-black': '#2d364e',
          '--colors-typography-dimgray': '#6b6b6b',
          '--colors-typography-primary': '#2d364e',
          '--colors-typography-secondary': '#5d6b8e',
          '--colors-typography-disabled': '#abb8cf',
          '--colors-typography-svg': '#7a89aa',
          '--colors-typography-buttondisabled': '#96a4c0',
          '--colors-backgrounds-purewhite': '#ffffff',
          '--colors-backgrounds-grayindependence': '#44506f',
          '--colors-backgrounds-brightgray': '#e6ebf3',
          '--colors-backgrounds-ghostwhite-2': '#f5f8fb',
          '--colors-backgrounds-aurometalsaurus': '#6C7280',
          '--colors-backgrounds-primaryhover': '#0e17ac',
          '--colors-box-shadow-black-1': 'rgba(0, 0, 0, 0.1)',
          '--colors-box-shadow-black-15': 'rgba(0, 0, 0, 0.15)',
          '--colors-box-shadow-dodgerblue-3': 'rgba(0, 122, 255, 0.3)',
          '--colors-box-shadow-greychateau-2': 'rgba(149, 157, 165, 0.2)',
          '--colors-box-shadow-graysolitude': '#e6ebf3',
          '--colors-box-shadow-purewhite-6': 'rgba(255, 255, 255, 0.6)',
        },
      }}
    />
  );
}
