/* eslint-disable no-unused-vars */
import {CSSProperties} from 'react';

import {
  PaletteColorOptions,
  Shadows,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import {Check, Minus} from '@phosphor-icons/react';
import color from 'color';
import colors from 'tailwindcss/colors';

import {inter} from './fonts';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    caption1: true;
    caption2: true;
    caption3: true;
    body3: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }

  interface Palette {
    black: PaletteColor;
    light: PaletteColor;
    gray: PaletteColor;
    white: PaletteColor;
    grays: PaletteColor;
  }

  interface PaletteOptions {
    black: PaletteColorOptions;
    light: PaletteColorOptions;
    gray: PaletteColorOptions;
    white: PaletteColorOptions;
    grays: PaletteColorOptions;
  }

  interface PaletteColor {
    medium?: string;
    neutral?: string;
    textGray?: string;
    fifty?: string;
    one?: string;
    two?: string;
    three?: string;
    thirty?: string;
    four?: string;
    five?: string;
    six?: string;
    seven?: string;
    eight?: string;
    nine?: string;
    nineFifty?: string;
  }

  interface SimplePaletteColorOptions {
    medium?: string;
    neutral?: string;
    textGray?: string;
    fifty?: string;
    one?: string;
    two?: string;
    three?: string;
    thirty?: string;
    four?: string;
    five?: string;
    six?: string;
    seven?: string;
    eight?: string;
    nine?: string;
    nineFifty?: string;
  }

  interface TypographyVariants {
    subtitle3: CSSProperties;
    caption1: CSSProperties;
    caption2: CSSProperties;
    caption3: CSSProperties;
    body3: CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: CSSProperties;
    caption1?: CSSProperties;
    caption2?: CSSProperties;
    caption3?: CSSProperties;
    body3?: CSSProperties;
  }

  interface Theme {
    colors: {
      gray: TailwindColors;
      red: TailwindColors;
      orange: TailwindColors;
      yellow: TailwindColors;
      green: TailwindColors;
      blue: TailwindColors;
      purple: TailwindColors;
      pink: TailwindColors;
    };
    sidebarWidth: number;
  }

  interface ThemeOptions {
    colors: {
      gray: TailwindColors;
      red: TailwindColors;
      orange: TailwindColors;
      yellow: TailwindColors;
      green: TailwindColors;
      blue: TailwindColors;
      purple: TailwindColors;
      pink: TailwindColors;
    };
    sidebarWidth?: number;
  }
}

type TailwindColors = Record<
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | '950',
  string
>;

export default responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        lg: 1100 + 24 * 2,
        md: 800 + 24 * 2,
        // Accounts for <Container /> gutters
        sm: 500 + 24 * 2,
        xl: 1400 + 24 * 2,
        xs: 0,
        xxl: 1700 + 24 * 2,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          variant: 'outlined',
        },
        styleOverrides: {
          root: ({ownerState, theme}) => {
            const sharedProps = {
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '20px',
              minWidth: 0,
              height: ownerState.size === 'large' ? '36px' : '32px',
              boxShadow: 'none',
              padding: ownerState.size === 'large' ? '0 12px' : '0 10px',
              borderRadius: '6px',
            };

            if (ownerState.variant === 'contained') {
              const palette =
                ownerState.color === 'warning'
                  ? theme.colors.red
                  : theme.colors.blue;

              return {
                ...sharedProps,
                background: palette['600'],
                '&:hover': {
                  background: palette['700'],
                },
                '&:active': {
                  background: palette['800'],
                },
                '&:disabled': {
                  background: color(palette['600']).lighten(0.5).string(),
                  color: 'rgba(255, 255, 255, 0.8)',
                  pointerEvents: 'auto',
                  cursor: 'not-allowed',
                },
                '&:disabled:hover': {
                  background: color(palette['600']).lighten(0.5).string(),
                },
              };
            }

            if (ownerState.variant === 'outlined') {
              return {
                ...sharedProps,
                background: '#fff',
                borderColor: theme.colors.gray['300'],
                color:
                  ownerState.color === 'warning'
                    ? theme.colors.red['700']
                    : theme.colors.gray['700'],
                '&:hover': {
                  borderColor: theme.colors.gray['300'],
                  background: theme.colors.gray['50'],
                },
                '&:active': {
                  background: theme.colors.gray['100'],
                },
                '&:disabled': {
                  background: theme.colors.gray['50'],
                  borderColor: theme.colors.gray['300'],
                  color:
                    ownerState.color === 'warning'
                      ? color(theme.colors.red['700']).alpha(0.3).string()
                      : 'rgba(0, 0, 0, 0.3)',
                  pointerEvents: 'auto',
                  cursor: 'not-allowed',
                },
                '&:disabled:hover': {
                  background: theme.colors.gray['50'],
                  borderColor: theme.colors.gray['300'],
                  color:
                    ownerState.color === 'warning'
                      ? color(theme.colors.red['700']).alpha(0.3).string()
                      : 'rgba(0, 0, 0, 0.3)',
                },
              };
            }

            if (ownerState.variant === 'text') {
              return {
                ...sharedProps,
                border: '1px solid transparent',
                color: theme.colors.gray['700'],
                '&:hover': {
                  borderColor: theme.colors.gray['50'],
                  background: theme.colors.gray['50'],
                },
                '&:active': {
                  borderColor: theme.colors.gray['100'],
                  background: theme.colors.gray['100'],
                },
                '&:disabled': {
                  pointerEvents: 'auto',
                  cursor: 'not-allowed',
                },
              };
            }

            return {};
          },
          startIcon: () => {
            return {
              marginLeft: '-2px',
              marginRight: '6px',
              '& svg': {
                fontSize: '16px !important',
              },
            };
          },
          endIcon: () => {
            return {
              marginLeft: '6px',
              marginRight: '-2px',
              '& svg': {
                fontSize: '16px !important',
              },
            };
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ownerState, theme}) => {
            let size = '32px';
            let fontSize = '20px';

            if (ownerState.size === 'small') {
              size = '28px';
              fontSize = '18px';
            }

            if (ownerState.size === 'large') {
              size = '36px';
            }

            return {
              fontSize,
              width: size,
              height: size,
              borderRadius: '6px',
              color: theme.colors.gray['700'],
              padding: 0,
              '&:hover': {
                background: theme.colors.gray['50'],
              },
              '&:active': {
                background: theme.colors.gray['100'],
              },
              '&:disabled': {
                background: theme.colors.gray['50'],
                color: 'rgba(0, 0, 0, 0.3)',
                pointerEvents: 'auto',
                cursor: 'not-allowed',
              },
              '&:disabled:hover': {
                background: theme.colors.gray['50'],
                color: 'rgba(0, 0, 0, 0.3)',
              },
            };
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          icon: <span />,
          checkedIcon: (
            // Wrap the icon with <span>, because MUI assigns a font-size attribute,
            // which overrides the font-size CSS prop defined in this theme
            <span>
              <Check weight="bold" />
            </span>
          ),
          indeterminateIcon: (
            <span>
              <Minus weight="bold" />
            </span>
          ),
        },
        styleOverrides: {
          root: ({theme}) => {
            return {
              width: '18px',
              height: '18px',
              border: '1px solid',
              borderColor: theme.colors.gray['300'],
              borderRadius: '6px',
              padding: 0,
              background: '#fff',
              '&.Mui-checked': {
                borderColor: theme.colors.blue['500'],
                background: theme.colors.blue['500'],
                color: '#fff',
                fontSize: '14px',
              },
              '&.MuiCheckbox-indeterminate': {
                color: theme.colors.gray['500'],
                fontSize: '14px',
              },
              '&.Mui-disabled': {
                opacity: 0.6,
              },
            };
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ownerState}) => {
            return {
              padding: 0,
              width: ownerState.size === 'small' ? '36px' : '44px',
              height: ownerState.size === 'small' ? '20px' : '24px',
            };
          },
          track: ({theme}) => {
            return {
              borderRadius: '9999px',
              opacity: 1,
              background: theme.colors.gray['200'],
            };
          },
          switchBase: ({ownerState, theme}) => {
            return {
              padding: '0 !important',
              top: '2px',
              left: '2px',
              '&:hover': {
                background: 'transparent',
              },
              '&.Mui-checked': {
                transform: `translateX(${
                  ownerState.size === 'small' ? 16 : 20
                }) !important`,
              },
              '&.Mui-checked + .MuiSwitch-track': {
                opacity: 1,
                background: theme.colors.blue['500'],
              },
            };
          },
          thumb: ({ownerState}) => {
            return {
              width: `${ownerState.size === 'small' ? 16 : 20}px !important`,
              height: `${ownerState.size === 'small' ? 16 : 20}px !important`,
              background: '#fff',
              borderRadius: '9999px',
            };
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: () => {
            return {
              gap: '8px',
            };
          },
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          slotProps: {
            typography: {
              variant: 'body2',
            },
          },
        },
        styleOverrides: {
          root: () => {
            return {
              margin: 0,
              gap: '8px',
            };
          },
          label: () => {
            return {
              userSelect: 'none',
            };
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: ({theme}) => {
            return {
              background: `${theme.palette.grays.seven}90`,
            };
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
            boxShadow:
              '0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.04)',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({theme}) => {
            return {
              fontSize: '20px',
              fontWeight: '600',
              textTransform: 'capitalize',
              color: theme.palette.grays.nine,
            };
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: () => {
            return {
              padding: '0 16px 16px',
            };
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': {
              background: 'transparent !important',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '6px',
            border: '1px solid rgba(0, 0, 0, .1)',
            boxShadow:
              '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ownerState, theme}) => {
            const sharedProps = {
              fontWeight: 'bold',
              textTransform: 'uppercase' as const,
            };

            if (ownerState.color === 'primary') {
              return {
                ...sharedProps,
                background: theme.colors.blue['500'],
                color: '#fff',
              };
            }

            return sharedProps;
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            display: 'block',
          },
        },
      },
      MuiRadio: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: () => {
            return {
              textTransform: 'capitalize',
              minHeight: '48px',
              minWidth: 0,
              padding: '12px',
            };
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '6px',
            width: '100%',
            height: '32px',
            padding: 0,
          },
          input: {
            height: '100%',
            padding: '0 8px',
            fontSize: '14px',
            '&[type="search"]::-webkit-search-cancel-button': {
              appearance: 'none',
            },
          },
          adornedStart: {
            '& .MuiInputAdornment-positionStart': {
              height: '100%',
              maxHeight: 'unset',
              margin: '0 -2px 0 6px',
            },
          },
          adornedEnd: {
            '& .MuiInputAdornment-positionEnd': {
              height: '100%',
              maxHeight: 'unset',
              margin: '0 6px',
            },
          },
        },
      },
    },
    colors: {
      gray: colors.slate,
      red: colors.red,
      orange: colors.orange,
      yellow: colors.yellow,
      green: colors.green,
      blue: colors.blue,
      purple: colors.purple,
      pink: colors.pink,
    },
    palette: {
      black: {
        contrastText: '#fff',
        dark: '#000',
        main: '#2D364E',
      },
      error: {
        main: '#f44336',
      },
      gray: {
        main: '#909090',
      },
      grays: {
        dark: '#7a89aa',
        main: '#7a89aa',
        light: '#f5f8fb',
        medium: '#D6DEEA',
        neutral: '#e6ebf3',
        one: '#f5f8fb',
        three: '#D6DEEA',
        four: '#abb8cf',
        six: '#7a89aa',
        seven: '#5d6b8e',
        eight: '#44506f',
        nine: '#2D364E',
      },
      light: {
        contrastText: '#6B6B6B',
        dark: '#F0F0F0',
        main: '#F7F7F7',
        one: '#eff9fe',
      },
      mode: 'light',
      primary: {
        contrastText: '#fff',
        dark: '#0e17ac',
        four: '#8fb9f7',
        light: '#E8EEFB',
        main: '#3E5FE4',
        three: '#009DFF',
      },
      secondary: {
        dark: '#5D6B8E',
        contrastText: '#fff',
        light: '#5d6b8e',
        main: '#1c2131',
      },
      text: {
        disabled: '#96a4c0',
        primary: '#212A38',
        secondary: '#6B6B6B',
      },
      white: {
        main: '#fff',
      },
    },
    shadows: [
      'none',
      '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.05)',
      '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 12px rgba(0, 0, 0, 0.05)',
      ...Array.from({length: 22}).fill('none'),
    ] as Shadows,
    sidebarWidth: 240,
    typography: {
      body1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 24 / 16,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 20 / 14,
      },
      body3: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16 / 12,
      },
      caption1: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 24 / 16,
      },
      caption2: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 20 / 14,
      },
      caption3: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 16 / 12,
      },
      fontFamily: inter.style.fontFamily,
      // Headers have fontWeight of 700
      // Subtitles have a fontWeight of 600
      // Captions have a fontWeight of 500
      // Bodies have a fontWeight of 400
      h1: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h2: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h3: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h4: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h5: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h6: {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.25,
      },
      overline: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.25,
        textTransform: 'uppercase' as const,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 24 / 16,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 20 / 14,
      },
      subtitle3: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 16 / 12,
      },
    },
  })
);
/* eslint-enable no-unused-vars */
