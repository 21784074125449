import {ReactNode, forwardRef} from 'react';

import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import clsx from 'clsx';

import css from './Typography.module.css';

export type TypographyProps = MuiTypographyProps & {
  classnames?: Array<string> | string;
  children?: ReactNode | string;
  component?: string;
  color?: 'primary' | 'secondary' | 'disabled';
};

const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  ({children, classnames, color = 'primary', ...props}, ref) => {
    return (
      <MuiTypography
        ref={ref}
        {...props}
        className={clsx(['typography', css['typography'], classnames, color])}
      >
        {children}
      </MuiTypography>
    );
  }
);

Typography.displayName = 'Typography';

export default Typography;
